<template>
  <Layout :show-header="false">
    <ValidationObserver class="container pt-lg-md">
      <ValidationObserver class="row justify-content-center">
        <ValidationObserver class="col-lg-5">
          <div class="text-center mb-6">
            <router-link :to="{ name: 'home' }">
              <img class="h-8" src="@assets/svg/kegbit-logo-color.svg" />
            </router-link>
          </div>
          <ValidationObserver v-slot="{ passes }">
            <div class="card">
              <div v-if="error" class="card-alert alert alert-danger mb-0">
                {{ error }}
              </div>
              <div class="card-body p-6">
                <div :class="[loggingIn ? 'active' : '']" class="dimmer">
                  <div class="loader"></div>
                  <div class="dimmer-content">
                    <div class="text-muted text-center mb-3">Sign in with</div>

                    <div class="btn-list text-center">
                      <button
                        class="btn btn-secondary"
                        type="button"
                        @click="loginGoogle"
                      >
                        <img src="@assets/svg/google.svg" class="mr-3" /> Google
                      </button>

                      <button
                        class="btn btn-secondary"
                        type="button"
                        @click="loginGithub"
                      >
                        <img src="@assets/svg/github.svg" class="mr-3" /> Github
                      </button>
                    </div>

                    <div class="d-block py-4 text-muted text-center">
                      Or sign in with credentials
                    </div>
                    <form
                      action
                      method="post"
                      novalidate
                      @submit.prevent="passes(loginUser)"
                    >
                      <ValidationProvider
                        v-slot="{ errors, classes, invalid }"
                        name="email"
                        rules="required|email"
                      >
                        <div
                          class="input-icon mb-3"
                          :class="{ 'is-invalid': invalid }"
                        >
                          <span class="input-icon-addon">
                            <i class="far fa-envelope"></i>
                          </span>

                          <input
                            v-model="email"
                            aria-describedby="emailHelp"
                            class="form-control"
                            placeholder="Email"
                            type="email"
                          />
                          <span class="invalid-feedback">{{ errors[0] }}</span>
                        </div>
                      </ValidationProvider>

                      <div class="input-icon mb-3">
                        <span class="input-icon-addon">
                          <i class="far fa-lock-open-alt"></i>
                        </span>

                        <input
                          v-model="password"
                          class="form-control"
                          placeholder="Password"
                          type="password"
                        />
                      </div>
                      <div class="d-block">
                        <router-link
                          :to="{ name: 'forgot-password' }"
                          class="small"
                          >Forgot password?</router-link
                        >
                      </div>

                      <div class="form-footer text-center">
                        <button
                          class="btn btn-primary px-4"
                          type="button"
                          @click.prevent="loginUser"
                        >
                          Sign In
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </ValidationObserver>
        </ValidationObserver>
      </ValidationObserver>
    </ValidationObserver>
  </Layout>
</template>

<script>
import { mapState } from 'vuex';
import Layout from '@layouts/Main';
import { auth } from '@src/services/firebase/init';
import firebase from 'firebase/app';
// import isNil from 'lodash/isNil';
import { desktop as isDesktop } from 'is_js';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  name: 'Login',
  components: { Layout, ValidationObserver, ValidationProvider },
  data: () => ({
    email: '',
    password: '',
    error: '',
    rememberMe: true,
    loggingIn: false,
    untappdClientId: process.env.VUE_APP_UNTAPPD_CLIENTID,
    untappdCallbackUrl: escape(process.env.VUE_APP_UNTAPPD_CALLBACK),
  }),
  computed: {
    ...mapState('auth', ['user']),
  },
  created() {
    auth
      .getRedirectResult()
      .then((result) => {
        if (result.user) {
          this.$store.dispatch('auth/autoSignIn').then(() => {
            this.$router.push({ name: 'home' }).catch(() => {});
          });
        }
      })
      .catch((error) => {
        var errorMessage = error.message;

        this.error = errorMessage;
        this.loggingIn = false;
      });
  },

  methods: {
    async loginGoogle() {
      const provider = new firebase.auth.GoogleAuthProvider();

      provider.addScope('profile');
      provider.addScope('email');

      await this.SignIn(provider);
    },

    async loginGithub() {
      try {
        const provider = new firebase.auth.GithubAuthProvider();

        await this.SignIn(provider);
      } catch (err) {
        this.showError(err);
      }
    },

    async SignIn(provider) {
      try {
        if (isDesktop()) {
          await auth.signInWithPopup(provider);
          await this.$store.dispatch('auth/autoSignIn');

          // eslint-disable-next-line handle-callback-err
          this.$router.push({ name: 'home' }).catch(() => {});
        } else {
          auth.signInWithRedirect(provider);
        }
      } catch (err) {
        this.showError(err);
      }
    },

    async loginUser(e) {
      try {
        await auth.signInWithEmailAndPassword(this.email, this.password);

        return this.$store
          .dispatch('auth/autoSignIn')
          .then(() => {
            this.$router.push({ name: 'home' }).catch(() => {});
          })
          .catch((err) => {
            this.showError(err);
          });
      } catch (err) {
        if (err.code === 'auth/user-not-found') {
          // create the user
          await auth.createUserWithEmailAndPassword(this.email, this.password);

          // console.log('newUser', newUser);

          return this.$store
            .dispatch('auth/autoSignIn')
            .then(() => {
              this.$router.push({ name: 'home' }).catch(() => {});
            })
            .catch((err) => {
              this.showError(err);
            });

          // eslint-disable-next-line handle-callback-err
        } else if (
          err.code === 'auth/account-exists-with-different-credential'
        ) {
          this.showError(err);
        } else {
          this.showError(err);
        }
      }
    },
    showError(err) {
      // console.error(JSON.stringify(err));
      this.error = err.message;
      this.loggingIn = false;
    },
  },
};
</script>
